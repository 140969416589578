import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
// import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { CommonImageProps } from '~/types/common-props'
import { cx, fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
// import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './DetroitAmmo.module.scss'
import useDetroitAmmoStaticQuery from './useDetroitAmmoStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

export const quotes = {
  ceo: {
    name: 'Dmytro',
    position: 'CEO & Founder at Codica',
  },
  pm: {
    name: 'Olena',
    position: 'Project Manager at Codica',
  },
  fe: {
    name: 'Eugene',
    position: 'Tech Lead & React expert at Codica',
  },
}

const previewDanTitle = 'Online domain name trading marketplace'
const previewPmkTitle = 'Online Service Marketplace'

const CaseStudiesDetroitAmmo = () => {
  const query = useDetroitAmmoStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  // const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Comprehensive multi-vendor online firearm store"
          subtitle="Learn how our experts helped the client to redevelop the existing FFL ecommerce platform into a custom-built solution, making it more user-friendly. We enhanced spam attack protection, added automation for admins, improved processes for users, and more."
          maxWidthSubtitle="425px"
          maxWidthInfo="385px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Comprehensive multi-vendor firearm store"
          imageTitle="Comprehensive multi-vendor firearm store"
          domain="E-commerce"
          location="Detroit, USA"
          timeline="October 2023 - Ongoing"
          services={
            <>
              Front-end, Back-end development,
              <Link
                to="/services/online-marketplace-development/"
                className="ml5"
                key="marketplaceKey"
              >
                Online marketplace development
              </Link>
              ,
              <Link
                to="/services/ui-ux-design/website-redesign-services/"
                className="ml5"
                key="redesignKey"
              >
                Website redesign services
              </Link>
            </>
          }
          team="2 Front-End Developers, 2 Back-End Developers, 1 QA Engineer, 1 DevOps Engineer, 1 UX/UI Designer, 2 Teach Leads, 1 Project Manager"
          technologies="Ruby on Rails, Next.js (React), Docker, PostgreSQL, Authorize.net, Elasticsearch, Mailgun, Easypost"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“At Codica, we are thrilled to have partnered with our client to upgrade their platform to new heights. By redesigning the architecture and transitioning the core functionality to Ruby and Next.js, we’ve significantly enhanced the user experience and overall performance. Our team rebuilt the interface, developed comprehensive features, and continued to provide robust support services.',
            },
            {
              text: 'By migrating to a more modern and flexible stack, we have enabled the client to deliver a faster, more user-friendly platform that meets their growing needs. At Codica, we take pride in transforming challenges into innovative solutions, ensuring our clients can achieve their full potential in the marketing landscape.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/case-study/ammo/CEO-square.png"
              alt={`${quotes.ceo.name}, ${quotes.ceo.position} at Codica`}
              title={`${quotes.ceo.name}, ${quotes.ceo.position}`}
              {...imageProps}
            />
          }
          name={quotes.ceo.name}
          position={quotes.ceo.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudyBackground__bgColor}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTitleLeftList="Project challenges"
        bgTextLeft={
          <>
            Detroit Ammo Co is the first multi-vendor marketplace, providing a
            platform where Federal Firearms Licensed (FFL) manufacturers can
            sell products without discrimination. Faced with budget constraints,
            payment system compatibility, legal regulations, and more
            challenges, we guided complexities to deliver a custom-built online
            firearm store that met the client’s objectives.
            <div className="mb-3" key="bgTextLeft" />
            Our experts focused on enhancing user experiences. Customers can
            interact with the platform to purchase firearms and ammunition, with
            the option to subscribe for easier product delivery. Vendors can
            register to sell products, manage storefronts, listings, and orders,
            and generate revenue through commission payments.
          </>
        }
        bgTextRight="Wholesalers can register to access unique pricing advantages and tax exemptions for bulk purchases after providing the required documentation. Admins can oversee all platform operations, including user management, item supervision, and online firearm sales monitoring."
        sectionBusiness={[
          {
            label:
              'Address slow page loading speeds caused by numerous custom plugins on the WordPress platform, which hinder user experience and functionality.',
          },
          {
            label:
              'Resolve payment system incompatibility with the sale of firearms and ammunition by finding a suitable solution that supports both single and subscription payments without restrictions.',
          },
          {
            label:
              'Guide complex US legal regulations around ammunition delivery and buyer verification by developing a robust checkout process to ensure compliance with state-specific laws.',
          },
          {
            label:
              'Ensure platform security by conducting comprehensive protection tests and implementing additional checks to protect user data, given the sensitive nature of the products sold.',
          },
          {
            label:
              'Migrate the active user base to the new firearm marketplace seamlessly to avoid losing traffic and maintain user engagement, requiring thorough data migration and consistent user outreach.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Developed a dynamic firearm marketplace with significantly faster loading times, enhanced product search and filtering capabilities, and improved overall site navigation, leading to a superior user experience.',
          },
          {
            label:
              'Implemented a specialized role and subscription model for wholesalers, allowing them access to exclusive pricing and free shipping alongside a system to verify legal entities and tax exemptions.',
          },
          {
            label:
              'Provided customizable storefront capabilities, streamlined stock management, and integrated shipping aggregator for efficient order processing.',
          },
          {
            label:
              'Created a robust checkout system that verified customer information and complied with local regulations, ensuring seamless product allocation and universal payment options.',
          },
          {
            label:
              'Introduced seamless product subscription functionality, allowing customers to set up recurring orders with automatic fulfillment and additional discounts, enhancing convenience and creating a predictable revenue stream.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__light}>
        <CaseStudyQuote
          quote={[
            {
              text: '“As part of this project, our team focused on updating and improving the existing marketplace for selling ammunition. The main goal was to refresh the user interface (UI) and enhance the user experience (UX). We implemented improvements to the search system, added filtering, and improved search and categorization. Special attention was given to product and account management, providing more capabilities for customers and vendors.',
            },
            {
              text: 'The purchasing system was also optimized to make the buying process more intuitive and faster.',
            },
            {
              text: 'For the frontend, we used advanced technologies such as React and Next.js, while for the backend, we utilized Ruby on Rails. This allowed us to create a modern, high-performance solution.',
            },
            {
              text: 'Our work has resulted in a more optimized and user-friendly marketplace that delivers a better user experience. We are happy to offer our expertise and skills to update marketplaces, helping them meet modern requirements and user expectations.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/case-study/ammo/FE-Eugene-square.png"
              alt={`${quotes.fe.name}, ${quotes.fe.position} at Codica`}
              title={`${quotes.fe.name}, ${quotes.fe.position}`}
              {...imageProps}
            />
          }
          name={quotes.fe.name}
          position={quotes.fe.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudyCta__bgColor}
        title="Do you want to develop a profitable marketplace platform?"
        description="Our experts employ the best industry approaches to realize your business idea."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div
        className={cx(
          containerStyles.caseStudy__bgLight,
          containerStyles.caseSolutionRing,
        )}
      >
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Enhanced user experience"
          description={[
            'Our commitment to enhancing user engagement led us to boost a dynamic gun marketplace that delivers improved page loading speeds and ensures seamless navigation for every visitor. Introducing an advanced attribute system revolutionizes product discovery, providing users with efficient search and filtering capabilities.',
            <div className="mb-2" key="descr" />,
            'Furthermore, our detailed restructuring of the product catalog guarantees a more intuitive navigation through the marketplace, fostering a user-friendly environment that facilitates exploration and purchase',
          ]}
          alt="Enhanced user experience | Codica"
          imageTitle="Enhanced user experience"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Wholesale subscription purchases"
          description={[
            'Our experts introduced a specialized role and subscription model explicitly tailored for wholesalers. So, we stimulated wholesale purchases by granting access to exclusive prices and free shipping, thereby boosting revenue growth for online gun marketplace.',
            <div className="mb-2" key="descr" />,
            'Moreover, we implemented a robust confirmation and verification system using business FFL license and tax exemption certificate verification to ensure the authenticity and legality of wholesale transactions.',
          ]}
          alt="Wholesale subscription purchases | Codica"
          imageTitle="Wholesale subscription purchases"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Vendor empowerment"
          description={[
            'Vendors can manage their storefronts, reflecting their brand identity through customizable features and enriched company profiles.',
            <div className="mb-2" key="descr" />,
            'With automated stock management and introducing a backorder system for popular ammunition, vendors can optimize inventory control and capitalize on demand fluctuations.',
            <div className="mb-2" key="descrTwo" />,
            'Integration with a shipping aggregator further strengthens online firearms sales efficiency, enabling the automatic generation of shipping labels and speeding up order processing.',
          ]}
          alt="Vendor empowerment | Codica"
          imageTitle="Vendor empowerment"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Seamless shopping following local restrictions"
          description={[
            'Adhering to strict regulatory requirements, we secured the firearm marketplace with robust checkout processes that validate customer information and ensure compliance with state-specific regulations.',
            <div className="mb-2" key="descr" />,
            'Our implementation of complex logic for product allocation in shopping carts ensures seamless compliance with local legislation and delivery regulations, guaranteeing a hassle-free shopping experience for every user.',
            <div className="mb-2" key="descrTwo" />,
            'Also, our universal payment solution supports various payment types, follows industry standards, and provides customers with a secure and convenient transaction experience.',
          ]}
          alt="Seamless shopping following local restrictions | Codica"
          imageTitle="Seamless shopping following local restrictions"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Streamlined deliveries with subscription management"
          description={[
            'Our online firearm store introduces a seamless product subscription functionality, allowing customers to streamline their ordering process and enjoy exclusive benefits. Customers can receive a set number of products every month without repetitive actions, avoiding forgetting to reorder and potentially switching to a competitor.',
            <div className="mb-2" key="descr" />,
            'Moreover, subscribers get additional discounts, making their purchases convenient and cost-effective. With this feature, we revolutionize how customers interact with our online firearm sales platform, enhancing loyalty and simplifying their shopping journey.',
          ]}
          alt="Streamlined deliveries with subscription management | Codica"
          imageTitle="Streamlined deliveries with subscription management"
        />
      </div>

      {/* <LazyHydrate whenVisible>
        <div className={containerStyles.ammoCaseStudyVideo}>
          <CaseStudyVideoCase
            title="How it works"
            description="Have you ever wondered how an online firearm marketplace operates? From browsing to purchasing, there's a streamlined process designed for ease and security. In this video, we take you behind the scenes to explore each step of a seamless buying experience."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink=""
          />
        </div>
      </LazyHydrate> */}

      <div className={containerStyles.caseStudyQuote__light}>
        <CaseStudyQuote
          quote={[
            {
              text: '“The challenge of this project was managing an existing customer base that was already familiar with the current interface. Our main goal was to create an improved product to meet and exceed customer expectations. We undertook extensive work to design a user flow that simplifies the purchasing process, aiming to boost customer loyalty and retention while streamlining order processing for vendors.',
            },
            {
              text: 'This project greatly boosted our product mindset. I am grateful to the team for the opportunity to collaborate closely on product improvements, delving into the smallest details and sharing insights to achieve the best possible result.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/case-study/ammo/PM-Olena-square.png"
              alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
              title={`${quotes.pm.name}, ${quotes.pm.position}`}
              {...imageProps}
            />
          }
          name={quotes.pm.name}
          position={quotes.pm.position}
        />
      </div>

      <div className={containerStyles.caseStudyTechSection}>
        <CaseStudyTech
          technologies={[
            { label: 'Puma', icon: 'puma' },
            { label: 'Ruby', icon: 'ruby' },
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'Docker', icon: 'docker' },
            { label: 'Elasticsearch', icon: 'elasticsearch' },
            { label: 'Next.js', icon: 'nextjs' },
            { label: 'Material UI', icon: 'material' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Amazon S3', icon: 'amazonS3' },
            { label: 'Amazon RDS', icon: 'amazonRds' },
          ]}
          integrations={[
            { label: 'Authorize.net', icon: 'authorize' },
            { label: 'Mailgun', icon: 'mailgun' },
            { label: 'Easypost', icon: 'esypost' },
          ]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewDanTitle}
              color="danColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/dan.png"
                  alt={`Case study: ${previewDanTitle} | Codica`}
                  title={`Case study: ${previewDanTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="marketplace-platform-for-trading-domain-names"
            />
            <CaseStudyPreview
              title={previewPmkTitle}
              color="pmkColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/pmk.png"
                  alt={`Case study: ${previewPmkTitle} | Codica`}
                  title={`Case study: ${previewPmkTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="kids-activities-service-marketplace"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesDetroitAmmo
