// extracted by mini-css-extract-plugin
export var ammoCaseStudyVideo = "bQ_mG";
export var caseContactFormSection = "bQ_kw";
export var caseSolutionRing = "bQ_mH";
export var caseStudyBackground__bgColor = "bQ_ks";
export var caseStudyBackground__lineColor = "bQ_kt";
export var caseStudyCta__bgColor = "bQ_lG";
export var caseStudyHead = "bQ_kn";
export var caseStudyHead__imageWrapper = "bQ_kp";
export var caseStudyProjectsSection = "bQ_kx";
export var caseStudyQuote__dark = "bQ_mC";
export var caseStudyQuote__light = "bQ_mD";
export var caseStudySolution__ringOne = "bQ_mF";
export var caseStudySolution__ringTwo = "bQ_kC";
export var caseStudyTechSection = "bQ_kv";
export var caseStudyVideo__ring = "bQ_kG";
export var caseStudy__bgDark = "bQ_km";
export var caseStudy__bgLight = "bQ_kl";